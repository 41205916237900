import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout.js";
import HeroSection from "../components/HeroSection";
import RenderMarkdown from "../components/utilities/RenderMarkdown.js";
import PreviewCompatibleImage from "../components/utilities/PreviewCompatibleImage.js";

const ImageMessagePageTemplate = ({ data }) => {
  return (
    <Layout
      imageObject={data.headerObject.imageObject}
      seoObject={data.seoObject}
    >
      <HeroSection>
        <div className="space-y-5 sm:space-y-10">
          <RenderMarkdown
            markdownContent={data.heroObject.title}
            className="text-center"
          />
          <div className="max-h-[30vh] max-w-3xl mx-auto overflow-hidden">
            <PreviewCompatibleImage
              imageObject={data.heroObject.imageObject}
              className="h-full overflow-hidden"
            />
          </div>
          <RenderMarkdown markdownContent={data.heroObject.description} />
        </div>
      </HeroSection>
    </Layout>
  );
};

const ImageMessagePage = ({ data }) => {
  return <ImageMessagePageTemplate data={data.markdownRemark.frontmatter} />;
};

export default ImageMessagePage;

export const pageQuery = graphql`
  query ImageMessagePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seoObject {
          slug
          domain
          description
          index
          title
          imageObject {
            alt
            image {
              childImageSharp {
                resize(width: 1200) {
                  src
                  height
                  width
                }
              }
            }
          }
        }
        headerObject {
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData(placeholder: NONE)
              }
            }
          }
        }
        heroObject {
          title
          imageObject {
            alt
            image {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          description
        }
      }
    }
  }
`;
